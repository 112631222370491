@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root {
  width: 100% !important;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}


header{
    overflow: hidden;
}
.typewriter {
    width: 19ch;
    -webkit-animation: typing 2s steps(15), blink .5s step-end infinite alternate;
            animation: typing 2s steps(15), blink .5s step-end infinite alternate;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px none;
    font-family: monospace;
    font-size: 2em;
}

@-webkit-keyframes typing {
    from {
        width: 0
    }
}

@keyframes typing {
    from {
        width: 0
    }
}

@-webkit-keyframes blink {
    50% {
        border-color: transparent
    }
}

@keyframes blink {
    50% {
        border-color: transparent
    }
}
.btn-primary{background:#8e24aa;border-color:#8e24aa}
