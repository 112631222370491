.typewriter {
    width: 19ch;
    animation: typing 2s steps(15), blink .5s step-end infinite alternate;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px none;
    font-family: monospace;
    font-size: 2em;
}

@keyframes typing {
    from {
        width: 0
    }
}

@keyframes blink {
    50% {
        border-color: transparent
    }
}